import React from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { mq } from '../../styles/theme'

const SCurveStep = ({ orientation, background, image, subHeading, heading, copy, rotation, alt }) => {
  const stepImage = getImage(image)
  return (
    <SCurveStepWrapper orientation={orientation} background={background}>
      <SCurveStepContent>
        {subHeading && <SCurveStepSubHeading>{subHeading}</SCurveStepSubHeading>}
        <SCurveStepHeading>{heading}</SCurveStepHeading>
        {copy && <p>{copy}</p>}
      </SCurveStepContent>
      <SCurveBackground>
        <GatsbyImage image={stepImage} alt={alt} />
      </SCurveBackground>
      <SCurveStepOrb orientation={orientation} background={background} rotation={rotation} style={{ transform: `translate(-50%, 0) rotateZ(${rotation}deg)` }}/>
    </SCurveStepWrapper>
  )
}
export default SCurveStep

const SCurveStepWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: ${props => (props.orientation === 'left' ? 'row-reverse' : 'row')};
  flex-wrap: wrap;
  gap: 17px;
  justify-content: center;
  margin-block-end: 90px;
  position: relative;

  ${mq.desktop} {
    gap: 40px;
    flex-wrap: nowrap;
    margin-block-end: 60px;
  }
`

const SCurveStepContent = styled.div`
  flex: 50%;
  max-inline-size: 445px;
`

const SCurveStepSubHeading = styled.h5`
  font-family: "BuckeyeSans";
  font-weight: 700;
  font-size: 15px;
  margin-block-end: 10px;

  ${mq.tablet} {
    font-size: 16px;
  }
`

const SCurveStepHeading = styled.h3`
  font-family: "BuckeyeSans";
  font-weight: 600;
  font-size: 25px;
  line-height: 31px;
  margin-block-end: 8px;

  ${mq.tablet} {
    font-size: 33px;
    line-height: 41px;
    margin-block-end: 14px;
  }
`

const SCurveBackground = styled.div`
  z-index: 1;
`

const SCurveStepOrb = styled.div`
  //opacity: 0.5;
  background: url(${props => props.background}) no-repeat center center / contain;
  content: '';
  height: 100%;
  left: ${props => props.orientation === 'left' ? '5%' : 'auto'};
  right: ${props => props.orientation === 'left' ? 'auto' : '-55%'};
  bottom: -30%;
  position: absolute;
  width: 65%;

  ${mq.tablet} {
    left: ${props => props.orientation === 'left' ? '5%' : 'auto'};
    right: ${props => props.orientation === 'left' ? 'auto' : '-45%'};
    bottom: -35%;
    width: 60%;
  }

  ${mq.desktop} {
    left: ${props => props.orientation === 'left' ? '5%' : 'auto'};
    right: ${props => props.orientation === 'left' ? 'auto' : '-35%'};
    bottom: -25%;
    width: 40%;
  }

  ${mq.large} {
    left: ${props => props.orientation === 'left' ? '10%' : 'auto'};
    right: ${props => props.orientation === 'left' ? 'auto' : '-30%'};
  }
  }
`
