import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import { mq } from '../../styles/theme'
import SCurveStep from '../SCurveStep'
import PrimaryOrb from '../../assets/orb_1.png'
import SecondaryOrb from '../../assets/orb_2.png'
import TertiaryOrb from '../../assets/orb_3.png'
import useOnScreen from '../../hooks/useOnScreen'

const SCurveRow = props => {
  const orbBackground = [PrimaryOrb, SecondaryOrb, TertiaryOrb]
  const {
    field_prgph_heading_plain_text,
    relationships: { field_content_card_rows }
  } = props

  const ref = useRef();
  const [rotation, setRotation] = useState(0);
  const onScreen = useOnScreen(ref);
  let [y, setY] = useState(null);
  if (typeof window !== 'undefined'){
    // eslint-disable-next-line react-hooks/rules-of-hooks
    [y, setY] = useState(window.scrollY);
  }

  useEffect(() => {
    if (typeof window !== 'undefined'){
      window.addEventListener('scroll', animateImage);
      return () => window.removeEventListener('scroll', animateImage);
    }
  });

  const animateImage = e => {
    const window = e.currentTarget;

    if (y > window.scrollY) {
      if (onScreen) {
        setRotation(rotation + 0.5)
      }
    } else if (y < window.scrollY) {
      if (onScreen){
        setRotation(rotation - 0.5)
      }
    }
    setY(window.scrollY);
  }
  return (
    <SCurveWrapper>
      <SCurveSection ref={ref}>
        {field_prgph_heading_plain_text && <SCurveRowHeading>{field_prgph_heading_plain_text}</SCurveRowHeading>}
        {
          field_content_card_rows && field_content_card_rows.map((el, i) => {
            const SCurveImage = el.relationships.field_prgph_image.relationships.field_media_image.localFile
            const SCurveImageAlt = el.relationships.field_prgph_image.field_media_image.alt;
            return (
              <SCurveStep
                key={i}
                image={SCurveImage}
                background={orbBackground[i % orbBackground.length]}
                subHeading={el.field_prgph_sub_headi}
                alt={SCurveImageAlt}
                heading={el.field_prgph_heading_plain_text}
                copy={el.field_prgph_copy}
                rotation={rotation}
                orientation={i % 2 === 0 ? 'right' : 'left'}
              />
            )
          })
        }
      </SCurveSection>
    </SCurveWrapper>
  )
}

export default SCurveRow

const SCurveWrapper = styled.section`
  overflow-x: hidden;

  ${mq.tablet} {
    overflow-x: initial;
  }
`

const SCurveSection = styled.div`
  margin-block-start: 100px;
  padding-block-end: 20px;
  padding-inline: 20px;
  overflow: hidden;

  ${mq.tablet} {
    padding-inline: 200px;
  }
`

const SCurveRowHeading = styled.h2`
  font-family: "BuckeyeSans";
  font-weight: 700;
  font-size: 30px;
  line-height: 32px;
  margin-block-end: 40px;
  text-align: center;

  ${mq.tablet} {
    font-size: 40px;
    line-height: 42px;
    margin-block-end: 50px;
  }
`
